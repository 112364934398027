<template>
  <div id="depature-group">
    <div class="container-fluid">
      <b-card class="mb-4 pt-4" header-tag="header">
        <template #header>
          <h5>Buat Grup Keberangkatan</h5>
          <b-row class="border-bottom pb-4">
            <b-col cols="12" sm="6" md="4" lg="3">
              <form-group-input
                placeholder=""
                width-background
                type="text"
                v-model="newDepatureName"
                label="Nama Grup Keberangkatan"
                :need-label="true"
                rules="required"
              />
            </b-col>
            <b-col cols="12" sm="6" md="4" lg="3">
              <form-group-input
                ref="depatureDate"
                placeholder=""
                width-background
                type="date"
                v-model="newDepatureDate"
                label="Bulan Keberangkatan"
                :need-label="true"
                rules="required"
              />
            </b-col>
          </b-row>
        </template>
        <div class="w-100">
          <b-row>
            <b-col cols="3">
              <form-group-input
                ref="filter-pilgrim"
                type="text"
                placeholder="Cari nama jama'ah"
                :need-label="true"
                label="Jama'ah"
                v-model="filter.pilgrim"
                id="filter-pilgrim"
              />
            </b-col>
            <b-col cols="3">
              <form-group-input
                ref="filter-pilgrim"
                type="enum"
                placeholder="Cari nama jama'ah"
                :need-label="true"
                :options="[
                  { value: '10', text: 'Paket 10 Hari' },
                  { value: '13', text: 'Paket 13 Hari' }
                ]"
                label="Total Hari Paket"
                v-model="filter.total_day"
                id="filter-pilgrim"
              />
            </b-col>
            <b-col class="pt-4">
              <b-button @click="doFilter" deep-hidden variant="primary">Cari</b-button>
              <b-button class="ml-2" @click="clearForm" deep-hidden variant="warning">
                <div class="text-white">
                  <b-icon icon="x"></b-icon>
                </div>
              </b-button>
            </b-col>
          </b-row>
          <paginated-table
            ref="dataTable"
            aksi-dot
            with-select
            :delete-reason="false"
            :can-delete="false"
            :can-edit="false"
            :data-url="apiUrl"
            :data-columns="columns"
            :dataParams="dataParams"
            :defaultDataParams="filter"
            @selection-change="handleSelectionChange"
          >
            <template slot="content_user" slot-scope="defaultSlotScope">
              <span>{{ defaultSlotScope.colData.pilgrim.name || "-" }}</span>
            </template>
            <template slot="content_package" slot-scope="defaultSlotScope">
              <span>{{
                defaultSlotScope.colData.details[0].orderdetailable.name || "-"
              }}</span>
            </template>
            <template slot="content_phone_number" slot-scope="defaultSlotScope">
              <span>{{
                defaultSlotScope.colData.pilgrim.phone_number || "-"
              }}</span>
            </template>
            <template slot="content_user_nik" slot-scope="defaultSlotScope">
              <span>{{ defaultSlotScope.colData.pilgrim.nik || "-" }}</span>
            </template>
          </paginated-table>
          <div class="d-flex justify-content-end">
            <b-btn @click="postDepatureGroup" deep-hidden variant="success">
              <span v-if="$route.params.id">Perbarui Data</span>
              <span v-else>Buat Kelompok</span>
            </b-btn>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import FormGroupInput from "@/components/FormGroupInput";
import Order from "@/models/Order";
import DepatureGroup from "@/models/DepatureGroup";
import PaginatedTable from "@/components/PaginatedTable";

const OrderModel = new Order();
const DepatureGroupModel = new DepatureGroup();

export default {
  components: {
    FormGroupInput,
    PaginatedTable,
  },
  data: () => ({
    apiUrl: OrderModel.endpoint,
    depatureGroup: null,
    boardList: 3,
    draggableCard: null,
    orders: new Array(),

    /* column */
    dataParams: [
      "pilgrim",
      "total_day",
    ],
    filter: {
      pilgrim: null,
      total_day: ''
    },
    columns: [
      {
        prop: "created_at",
        label: "Tgl. Order",
        resizable: true,
        align: "left",
        sortable: false,
        minWidth: 200,
        formatThisDate: true,
      },
      {
        prop: "user",
        label: "Jama'ah",
        resizable: true,
        align: "left",
        sortable: false,
        minWidth: 200,
      },
      {
        prop: "package",
        label: "Paket",
        align: "left",
        minWidth: 200
      },
      {
        prop: "nomor_va",
        label: "VA Nomor",
        resizable: true,
        align: "center",
        sortable: false,
        minWidth: 150,
      },
      {
        prop: "user_nik",
        label: "NIK",
        resizable: true,
        align: "center",
        sortable: false,
        minWidth: 200,
      },
      {
        prop: "phone_number",
        label: "Nomor Handphone",
        resizable: true,
        align: "center",
        sortable: false,
        minWidth: 150,
      },
    ],

    /* depature field */
    newDepatureName: null,
    newDepatureDate: null,
    pilgrimChoose: new Array(),
  }),
  methods: {
    postDepatureGroup: function() {
      if (this.$route.params.id) {
        DepatureGroupModel.update(this.$route.params.id, {
          name: this.newDepatureName,
          date: this.newDepatureDate,
        })
          .then((resp) => {
            if (this.pilgrimChoose.length > 0) {
              let addPilgrim = [];
              console.log(resp)
              this.pilgrimChoose.forEach((data) => {
                addPilgrim.push(
                  DepatureGroupModel.addPilgrim({
                    pilgrim_id: data.pilgrim.id,
                    depature_group_id: resp.id,
                  })
                );
              });

              Promise.all(addPilgrim)
                .then((res) => {})
                .catch((err) => {});
            }
            this.$store.dispatch(
              "notification/success",
              "Group Keberangkatan Berhasil ditambahkan."
            );
            this.$router.push("/depature-group");
          })
          .catch((err) => {
            console.log(err)
            this.$store.dispatch("notification/error", err);
          });

        return;
      }
      DepatureGroupModel.store({
        name: this.newDepatureName,
        date: this.newDepatureDate,
      }).then((resp) => {
        let addPilgrim = [];
        this.pilgrimChoose.forEach((data) => {
          addPilgrim.push(
            DepatureGroupModel.addPilgrim({
              pilgrim_id: data.pilgrim.id,
              depature_group_id: resp.data.id,
            })
          );
        });

        Promise.all(addPilgrim)
          .then((res) => {
            this.$store.dispatch(
              "notification/success",
              "Group Keberangkatan Berhasil ditambahkan."
            );
            this.$router.push("/depature-group");
          })
          .catch((err) => {
            this.$store.dispatch("notification/error", err);
          });
      });
    },
    formatDate: function(date) {
      return this.$moment(date).format(process.env.VUE_APP_DATE_FORMAT_SHORT);
    },
    handleSelectionChange: function(val) {
      this.pilgrimChoose = val;
    },
    getOrder: function() {
      OrderModel.list({ progress: "grouping" }).then((resp) => {
        this.orders = resp.data;
      });
    },
    getDepatureGroup: function() {
      DepatureGroupModel.list().then((resp) => {
        this.depatureGroup = resp;
      });
    },
    clearForm: function () {
      this.$nextTick(() => {
        this.filter = {
          pilgrim: null,
          total_day: ''
        }
        setTimeout(() => {
          this.doFilter()
        }, 100)
      })
    },
    doFilter: function () {
      this.$refs.dataTable.doFilter(this.filter)
    }
  },
  mounted: function() {
    if (this.$route.params.id) {
      DepatureGroupModel.find(this.$route.params.id).then((resp) => {
        this.newDepatureName = resp.name;
        this.newDepatureDate = resp.date;
        this.$refs.depatureDate.setValue(res.date);
      });
    }
  },
};
</script>
